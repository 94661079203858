<template>
  <div class="phone">phone</div>
</template>

<script>
import { reactive, toRefs } from '@vue/composition-api';

export default {
  name: 'PhoneLogin',
  components: {},
  setup() {
    const state = reactive({
      count: 0,
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped>
.phone {
}
</style>
