import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/utils/axios';
import utils from '@/utils';

Vue.use(Vuex);

const lang = utils.getLocalStorage('language') || utils.browerLanguage();

export default new Vuex.Store({
  state: {
    systemInfo: null,
    lang,
    enums: null,
  },

  mutations: {
    setLanguage(state, language) {
      utils.setLocalStorage('language', language);
      state.lang = language;
    },

    setSystemInfo(state, info = null) {
      state.systemInfo = info;
    },

    setEnums(state, enums = null) {
      state.enums = enums;
    },
  },

  actions: {
    // 获取登录前，系统的基础信息

    async getSystemInfo({ commit }, bsId) {
      if (!bsId) return;

      const { data } = await axios.get(`/general-login_api/bs/info/${bsId}`);

      commit('setSystemInfo', data.data);
    },

    // 获取字典数据

    async getEnums({ commit }) {
      const { data } = await axios.get('/general-login_api/lookups');

      const retData = (data.data || []).reduce((prev, next) => {
        if (prev[next.type]) {
          prev[next.type].push(next);
        } else {
          prev[next.type] = [next];
        }

        return prev;
      }, {});

      commit('setEnums', retData);

      return retData;
    },
  },

  modules: {},
});
