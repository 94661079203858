import Vue from 'vue';
import ElementUI from 'element-ui';
import VueCompositionAPI from '@vue/composition-api';
import App from './App';
import router from './router';
import store from './store';
import 'element-ui/lib/theme-chalk/index.css';
import './styles.scss';

import i18n from './lang'; // Internationalization

Vue.use(ElementUI);
Vue.use(VueCompositionAPI);
Vue.prototype.$ELEMENT = { size: 'medium' };
Vue.config.productionTip = false;

const initLang = el => {
  if (el) {
    if (i18n.locale === 'zh') {
      el.classList.remove('en');
      el.classList.add('zh');
    } else {
      el.classList.remove('zh');
      el.classList.add('en');
    }
  }
};

Vue.directive('lang', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted(el) {
    initLang(el);
  },
  update(el) {
    initLang(el);
  },
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
