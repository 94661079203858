function setLocalStorage(name, value) {
  window.localStorage.setItem(name, value);
}

function getLocalStorage(name) {
  return window.localStorage.getItem(name);
}

function removeLocalStorage(name) {
  window.localStorage.removeItem(name);
}

const browerLanguage = () => {
  const { language } = window.navigator;

  let innerLang = 'en';

  if (language && language.split('-').includes('zh')) {
    innerLang = 'zh';
  }

  return innerLang;
};

export default {
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
  browerLanguage,
};
