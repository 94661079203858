<template>
  <div class="home" :style="{ 'background-image': `url(${systemInfo.backgroundImgUrl}) ` }">
    <div class="header">
      <the-lang-select class="lang-select" />
    </div>
    <div class="main">
      <div class="logo" v-if="systemInfo.logoImgUrl">
        <img :src="systemInfo.logoImgUrl" />
      </div>
      <div class="content">
        <div class="left-wrapper">
          <img width="500" :src="systemInfo.personalImgUrl" />
        </div>

        <div class="right-wrapper">
          <div class="slogan">{{ systemInfo.loginSlogan }}</div>

          <div class="login-wrapper">
            <login :system-info="systemInfo" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import TheLangSelect from './the-lang-select';

import Login from './login';

export default {
  name: 'Home',
  components: { Login, TheLangSelect },
  setup(_, context) {
    const { $route, $store } = context.root;
    const bsId = computed(() => $route.query.bs_id);
    // 系统登录初始参数
    if (bsId.value) {
      $store.dispatch('getSystemInfo', bsId.value);
      $store.dispatch('getEnums');
    }
    const systemInfo = computed(() => $store.state.systemInfo || {});
    const enums = computed(() => $store.state.enums || {});
    return {
      systemInfo,
      enums,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.header {
  height: 2vh;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .lang-select {
    padding: 10px 10px;
  }
}
.main {
  height: 100vh - 2;
  display: flex;
  justify-content: center;
  align-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  .logo {
    position: absolute;
    top: 30px;
    left: 100px;
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    .left-wrapper {
      margin-right: 60px;
      margin-top: 80px;
    }

    .right-wrapper {
      width: 400px;
      .slogan {
        font-size: 24px;
        margin-bottom: 40px;
        text-align: center;
        color: #fff;
      }
      .login-wrapper {
        border-radius: 5px;
        background: #fff;
        box-shadow: 7px 7px 100px rgba(219, 218, 229, 0.4);
      }
    }
  }
}
</style>
