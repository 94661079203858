<template>
  <div class="login-index">
    <div class="tab-wrapper">
      <span
        v-for="(item, index) in innerTabs"
        :key="index"
        class="tab"
        :class="activeTab === item.id ? 'active' : ''"
        @click="activeTab = item.id"
      >
        {{ `${item.name}${$t('title.login')}` }}
      </span>
    </div>
    <div class="login" v-show="activeTab === 1000">
      <account-login />
    </div>
    <div class="login" v-show="activeTab === 1001">
      <phone-login />
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs } from '@vue/composition-api';
import AccountLogin from './account';
import PhoneLogin from './phone';

export default {
  name: 'LoginIndex',
  props: {
    systemInfo: {
      type: Object,

      default: () => {},
    },
  },
  components: { AccountLogin, PhoneLogin },
  setup(props, context) {
    const { $store } = context.root;
    const enums = computed(() => $store.state.enums || {});
    const state = reactive({
      activeTab: 1000,
    });
    const loginTypeArr = computed(() => props.systemInfo?.loginType?.split(',') || []);
    const innerTabs = computed(() => {
      const loginType = enums.value?.LoginType || [];
      const curLoginType = loginType.filter(item => loginTypeArr.value.includes(String(item.id)));
      return curLoginType;
    });
    return {
      ...toRefs(state),
      innerTabs,
    };
  },
};
</script>

<style lang="scss" scoped>
.login-index {
  min-height: 300px;

  .tab-wrapper {
    border-bottom: 1px solid #ddd;

    padding: 20px 40px 10px;

    .tab {
      cursor: pointer;

      margin-right: 30px;

      padding-bottom: 8px;

      &.active {
        color: #000;

        font-size: 18px;

        border-bottom: 3px solid var(--color-primary);
      }
    }
  }

  .login {
    margin-top: 40px;

    padding: 0 40px;
  }
}
</style>
