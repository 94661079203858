export default {
  systemName: '统一登录平台',
  title: {
    login: '登录',
    account: '账号/邮箱',
    password: '密码',
  },
  msg: {
    account: '请输入账号',
    password: '请输入密码',
  },
  btn: {
    login: '登录',
  },
};
