import Axios from 'axios';
import utils from './index';

const defaultLang = utils.getLocalStorage('language') || 'zh';

const langMap = {
  zh: 'zh-CN',
  en: 'en-US',
};

const axios = Axios.create({
  timeout: 20000, // 20s超时
});
// http request 拦截器
axios.interceptors.request.use(
  config => {
    config.headers['Content-Language'] = langMap[defaultLang];
    return config;
  },
  err => Promise.reject(err),
);
export default axios;
