import http from '@/utils/axios';
import { Message } from 'element-ui';
import { reactive, toRefs } from '@vue/composition-api';

export function useHttp(url) {
  // 定义初始变量
  const state = reactive({
    url,
    loading: false,
    data: null,
  });

  // 定义api请求方法
  const request = async (method, params, data) => {
    state.loading = true;
    try {
      const resData = await http.request({ url: state.url, method, params, data });
      const res = resData.data;
      state.loading = false;
      if (res.code === 0) {
        state.data = res.data;
        return res.data;
      }
      Message.error(res.message);
      return Promise.reject(res);
    } catch (error) {
      state.loading = false;
      if (error.response?.status === 500) {
        Message.error('网络异常，请稍后重试');
      }
      return Promise.reject(error);
    }
  };

  // get
  const getData = async params => {
    const resData = await request('get', params, undefined);
    return resData;
  };

  // post
  const postData = async data => {
    const resData = await request('post', undefined, data);
    return resData;
  };

  // put
  const putData = async data => {
    const resData = await request('put', undefined, data);
    return resData;
  };

  // put
  const deleteData = async data => {
    const resData = await request('delete', undefined, data);
    return resData;
  };

  return {
    ...toRefs(state),
    getData,
    postData,
    putData,
    deleteData,
  };
}
