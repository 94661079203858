import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ElementLocale from 'element-ui/lib/locale/index';
import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'; // element-ui lang
import enLocale from './en';
import zhLocale from './zh';
import utils from '../utils';

const defaultLang = utils.getLocalStorage('language') || utils.browerLanguage();

Vue.use(VueI18n);

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale,
  },
  zh: {
    ...zhLocale,
    ...elementZhLocale,
  },
};
const i18n = new VueI18n({
  // set locale
  // options: en | zh
  locale: defaultLang,
  fallbackLocale: defaultLang,
  // set locale messages
  messages,
});
ElementLocale.i18n((key, value) => i18n.t(key, value));
export default i18n;
