<template>
  <div class="account">
    <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="0px" class="ruleForm">
      <el-form-item prop="account">
        <el-input
          v-model="formData.account"
          :placeholder="$t('title.account')"
          @keydown.native.enter="submitLogin"
        />
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          show-password
          v-model="formData.password"
          :placeholder="$t('title.password')"
          @keydown.native.enter="submitLogin"
        />
      </el-form-item>

      <el-form-item>
        <el-button style="width: 100%" ref="TencentCaptcha" type="primary" @click="submitLogin">
          {{ $t('btn.login') }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from '@vue/composition-api';
import { useHttp } from '@/hooks/request';
import utils from '@/utils';
import i18n from '../../lang'; // Internationalization

export default {
  name: 'AccountLogin',
  components: {},
  setup(_, context) {
    const { $route } = context.root;
    const state = reactive({
      formData: {
        account: '',
        password: '',
      },
      rules: {
        account: [{ required: true, message: i18n.t('msg.account'), trigger: 'blur' }],
        password: [{ required: true, message: i18n.t('msg.password'), trigger: 'blur' }],
      },
    });
    const ruleForm = ref(null);
    const TencentCaptcha = ref(null);
    const { postData: toLogin } = useHttp('/general-login_api/login');
    const validLogin = async res => {
      if (res.ticket) {
        console.info(res);
        const data = await toLogin({
          ...state.formData,
          bsId: $route.query.bs_id,
          loginType: 'account',
          appId: res.appid,
          randStr: res.randstr,
          ticket: res.ticket,
        });
        const fromUrl = $route.query.redirect_url;
        utils.setLocalStorage($route.query.bs_id, data.businessToken);
        const hasQuery = (fromUrl?.match(/\?/g) || []).length > 0;
        if (hasQuery) {
          window.location = `${fromUrl}&token=${data.businessToken}`;
        } else {
          window.location = `${fromUrl}?token=${data.businessToken}`;
        }
      }
    };

    const tencentCaptcha = () => {
      const dom = TencentCaptcha.value.$el;
      const captcha1 = new window.TencentCaptcha(dom, '2035581235', validLogin);
      captcha1.show();
    };

    const submitLogin = () => {
      ruleForm.value.validate(async valid => {
        if (!valid) return;
        tencentCaptcha();
      });
    };
    onMounted(() => {});
    return {
      ...toRefs(state),
      submitLogin,
      ruleForm,
      TencentCaptcha,
    };
  },
};
</script>

<style lang="scss" scoped>
.account {
}
</style>
