export default {
  systemName: 'General Login Platform',
  title: {
    login: ' login',
    account: 'Account Or Email',
    password: 'Password',
  },
  msg: {
    account: 'Please Enter Account Or Email',
    password: 'Please Enter Password',
  },
  btn: {
    login: 'Sign In',
  },
};
