import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/home';
import i18n from '../lang'; // Internationalization

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/logout',
    name: 'Logout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/logout'),
  },
];

const systemName = i18n.t('systemName');
window.document.title = systemName;

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
