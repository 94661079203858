<template>
  <el-dropdown trigger="click" class="international" @command="handleSetLanguage">
    <span class="el-dropdown-link">Language<i class="el-icon-arrow-down el-icon--right" /></span>

    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="langItem in langList"
        :key="langItem.key"
        :disabled="$i18n.locale === langItem.key"
        :command="langItem.key"
      >
        {{ langItem.name }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      langText: {
        zh: '中文',
        en: 'English',
      },
    };
  },
  computed: {
    langList() {
      return Object.keys(this.langText).map(key => ({
        key,
        name: this.langText[key],
      }));
    },
  },

  methods: {
    ...mapMutations(['setLanguage']),
    handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.setLanguage(lang);
      this.$router.go(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dropdown-link {
  color: antiquewhite;
}
.el-dropdown-link:hover {
  color: #fff;
}
</style>
